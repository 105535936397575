export default {
  type: {
    app: {
      SET_BACKGROUND: "app/SET_BACKGROUND",
    },
    user: {
      SET_USER: "user/SET_USER",
    },
  },
};
